import React from 'react';
import styled, { css } from 'styled-components/macro';

const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text

  return str;
};

const domain = 'aaronsarnat.com';

const spamFlags = {
  links: [
    `http://`,
    `https://`,
    `@gmail.com`,
    `@hotmail.com`,
    `@yahoo.com`,
    `.online`
  ],
  keywords: [
    domain,
    `seo`,
    `fake id`,
    `get more sales for your`,
    `get yours here`,
    `limited time offer`,
    `fast shipping`,
    `only $`,
    `you won't want to miss`,
    `you wont want to miss`,
    `zero cost promotion`,
    `register your business here`,
    `we can deliver`,
    `free ad`,
    `price is just $`,
    `for your business`,
    `Stop paying `,
    `facebook advertising`,
    `your site generating leads`,
    `click here`,
    `dofollow link`
  ],
  emails: [
    `@${domain}`,
    `@idgod.ch`,
    `@business-directory.link`,
    `@topalexadirectory.top`,
    `@alexadirectory.link`
  ],
  orgs: [`gsa`]
};

const getFormActionPath = `https://getform.io/f/ab02ad4a-2980-4141-93c1-2da3e29a7c94`;
const honeyPotActionPath = `https://aaronsarnat.com/`;

const FIELD_TYPE = {
  TEXT: 'text',
  EMAIL: 'email',
  PHONE: 'tel',
  SUBMIT: 'submit',
  TEXTAREA: null
};

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilledByBot: false,
      messageValue: '',
      emailValue: '',
      orgValue: '',
      actionPath: honeyPotActionPath
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { isFilledByBot, messageValue, emailValue, orgValue } = this.state;

    // If not bot filled
    if (!isFilledByBot) {
      // If no org flags
      if (!new RegExp(spamFlags.orgs.join('|')).test(orgValue.toLowerCase())) {
        // If no email flags
        if (
          !new RegExp(spamFlags.emails.join('|')).test(emailValue.toLowerCase())
        ) {
          const caseInsensitiveMessage = messageValue.toLowerCase();
          const hasLinkFlags = new RegExp(spamFlags.links.join('|')).test(
            caseInsensitiveMessage
          );
          const hasSpamFlags = new RegExp(spamFlags.keywords.join('|')).test(
            caseInsensitiveMessage
          );
          const messageIsSpam = hasLinkFlags && hasSpamFlags;
          // if no message flags
          if (!messageIsSpam) {
            // Allow message to process
            this.setState({ actionPath: getFormActionPath });
          }
        }
      }
    }
  }

  render() {
    const { actionPath, isFilledByBot } = this.state;

    return (
      <FormContainer>
        <Form action={actionPath} onSubmit={this.handleSubmit}>
          {/* Name */}
          <Field label={`Your Name`} name={`Name`} required={true} />

          {/* Org */}
          <Field
            label={`Organization`}
            onChange={(e) => this.setState({ orgValue: e.target.value })}
          />

          {/* Phone */}
          <Field
            label={`Phone`}
            placeholder={`(555) 555-1234`}
            type={FIELD_TYPE.PHONE}
          />

          {/* Email */}
          <Field
            label={`Email`}
            required={true}
            placeholder={`example@domain.com`}
            type={FIELD_TYPE.EMAIL}
            onChange={(e) => this.setState({ emailValue: e.target.value })}
          />

          {/* Honeypot */}
          <Field
            label={`Homepage Website URL`}
            name={`url`}
            placeholder={`http://`}
            onChange={() =>
              !isFilledByBot ? this.setState({ isFilledByBot: true }) : {}
            }
          />

          {/* Message */}
          <Field
            label={`Message`}
            required={true}
            placeholder={`Tell me how I can help you.`}
            type={FIELD_TYPE.TEXTAREA}
            onChange={(e) => this.setState({ messageValue: e.target.value })}
          />

          {/* Submit */}
          <Submit />
        </Form>
      </FormContainer>
    );
  }
}

const Field = ({
  label = '',
  name = label,
  required = false,
  placeholder = null,
  type = FIELD_TYPE.TEXT,
  onChange = () => {}
}) => {
  let FormElement;

  switch (type) {
    case FIELD_TYPE.TEXT:
      FormElement = Input;
      break;
    case FIELD_TYPE.EMAIL:
      FormElement = Input;
      break;
    case FIELD_TYPE.PHONE:
      FormElement = Phone;
      break;
    case FIELD_TYPE.TEXTAREA:
      FormElement = Textarea;
      break;
    default:
      FormElement = Input;
  }

  const StyledLabel = required ? RequiredLabel : Label;

  const slug = slugify(name);
  const id = `ContactForm__FieldContainer__FormElement--${slug}`;

  return (
    <FieldContainer className={`ContactForm__FieldContainer--${slug}`}>
      <StyledLabel
        title={`${required ? 'Required' : 'Optional'} Field`}
        htmlFor={id}
        children={label}
      />
      <FormElement
        id={id}
        name={name}
        required={required}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
      />
    </FieldContainer>
  );
};

const Submit = () => (
  <SubmitContainer>
    <SubmitInput type={FIELD_TYPE.SUBMIT} value={`Send Message`} />
  </SubmitContainer>
);

const FormContainer = styled.div`
  max-width: 600px;
`;

const Form = styled.form.attrs({
  method: `post`
})``;

const FieldContainer = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 4fr;

  &.ContactForm__FieldContainer--email + div {
    display: none;
  }
`;

const Label = styled.label`
  cursor: default;
  display: block;
  padding-left: 0;
`;

const RequiredLabel = styled(Label)`
  font-weight: bold;

  &:after {
    content: '*';
  }
`;

const fieldStyles = css`
  display: block;
  cursor: text;
  padding: 8px 10px;
`;

const Input = styled.input`
  ${fieldStyles}
`;

const Phone = styled(Input).attrs({
  minLength: 7,
  maxLength: 15
})``;

const Textarea = styled.textarea`
  ${fieldStyles}
  min-height: 150px;
  resize: vertical;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

const SubmitInput = styled(Input)`
  ${fieldStyles}
  cursor: pointer;
`;

import React from 'react';
import Layout, { TEMPLATE } from '../components/Layout';
import ContactForm from '../components/ContactForm';

const Contact = () => (
  <Layout title="Contact" template={TEMPLATE.TEXT}>
    <h1>Contact Me</h1>
    <ContactForm />
  </Layout>
);

export default Contact;
